var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "keepRecord" },
    [
      _c(
        "van-tabs",
        {
          attrs: { animated: "", sticky: "" },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c(
            "van-tab",
            { attrs: { title: "存料管理", name: "0" } },
            [_c("keep-alive", [_c("keepAdmin")], 1)],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "存料结算", name: "1" } },
            [
              _c(
                "keep-alive",
                [
                  _c("keepJiesuan", {
                    attrs: { isWaitHandle: _vm.isWaitHandle }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "tabsRight" }, [
        _c("span", { on: { click: _vm.toMortgageStatement } }, [
          _vm._v("批量结价")
        ])
      ]),
      _c(
        "div",
        { staticClass: "tabsLeftIcon", on: { click: _vm.ToPerson } },
        [_c("van-icon", { attrs: { name: "arrow-left" } })],
        1
      ),
      (_vm.active == "0"
      ? true
      : false)
        ? _c("div", { staticClass: "top" }, [
            _c("div", { staticClass: "startTime" }, [_vm._v("开始时间")]),
            _c("div", { staticClass: "weight" }, [_vm._v("重量(g)")]),
            _c("div", { staticClass: "realTlmeMOney" }, [
              _vm._v("实时应收(元)")
            ]),
            _c("div", { staticClass: "state" }, [_vm._v("状态")])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }