<template>
<!-- 存料结算 -->
  <div class="keepJiesuan">
    <template v-if="ishow">
      <van-empty
        class="custom-image"
        :image="require('@/assets/images/pic_no information@2x.png')"
        description="暂无数据!"
      />
    </template>
      <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
            <van-list
                 v-model="loading"
                 :finished="finished"
                 finished-text="没有更多了"
                 @load="onLoad"
                 v-if="!ishow"
            >
      <!-- 单元格 -->
        <div v-for="(item) in list" :key="item.id" @click="ToStockBalance(item)">
          <div class="cell" v-if="isWaitHandle ? item.orderType === 'PRE_MORTGAGE' && item.payStatus =='WAIT_CONFIRM' ? true : false : item.mortgageFreeWeight === 0 || item.orderType === 'PRE_MORTGAGE'">
            <!-- 左侧标题 -->
            <div class="title" :style="{backgroundColor:item.orderType == 'MORTGAGE'?'#ff2d2e':'#feb706'}">{{item.orderType == "MORTGAGE"?'尾':'预'}}</div>
            <!-- 内容 -->
            <div class="flex">
                <div class="left">
                    <p class="totalWeight">来料总重 :  {{item.incomingWeight.toFixed(2)}}g</p>
                    <p class="DiscountWeight">折足重量 : {{item.weight.toFixed(2)}}g</p>
                    <div class="time">{{item.created | formatDate_}}</div>
                </div>
                <div class="right" :style="{color:item.payStatus =='WAIT_CONFIRM'?'red':item.payStatus =='WAIT'?'#ff2d2e':'#ccc'}">
                    <div class="config">{{item.payStatus =='WAIT_CONFIRM'?'待确认':item.payStatus =='PAYED'?'已收款':'待收款'}}</div>
                    <div class="money">{{item.fixedRemainMoney?item.fixedRemainMoney >0?'+'+item.fixedRemainMoney.toFixed(2):item.fixedRemainMoney.toFixed(2):item.money>0?'+'+item.money.toFixed(2):item.money}}</div>
                </div>
            </div>
            <!-- 右侧箭头 -->
          <van-icon name="arrow"/>
        </div>
        </div>
        </van-list>
        </van-pull-refresh>
  </div>
</template>

<script>
import *as api from'@/utils/api'
export default {
  props:['isWaitHandle'],
        data() {
            return {
                list:[],
                loading: false,
                finished: false,
                refreshing: false,
              ishow: true
            }
        },
  async created(){
    this.onLoad();
  },
        methods:{
            // 单元格点击事件
            ToStockBalance(item){
                this.$router.push({
                    name:'stockBalance',
                    query:{
                        id:item.id,
                        payStatus:item.payStatus,
                        orderType:item.orderType,
                      isWaitHandle: this.isWaitHandle,
                        }
                })
            },
            async onLoad() {
                    if (this.refreshing) {
                        this.list = [];
                        this.refreshing = false;
                    }
                    try{
                      let res = await api.getKeepJieSuanList()
                      this.list = res
                      this.finished = true;
                      this.loading = false;
                      if( this.list.length === 0){
                        this.ishow = true;
                      } else {
                        this.ishow = false;
                      }
                      let count = 0
                      res.forEach((items,index)=>{
                        if(this.isWaitHandle) {
                          if(items.orderType === 'PRE_MORTGAGE' && items.payStatus =='WAIT_CONFIRM') {
                            count += 1
                          }
                        }
                      })
                      if(count === 0 && this.isWaitHandle){
                        this.ishow = true;
                      }
                    } catch {
                      this.ishow = true;
                    }
    },
            onRefresh() {
                 // 清空列表数据
                 this.finished = false;

                 // 重新加载数据
                 // 将 loading 设置为 true，表示处于加载状态
                 this.loading = true;
                 this.onLoad();
            },
        },
}
</script>

<style lang="scss" scoped>
.custom-title {
    margin-right: 4px;
    vertical-align: middle;
}

.search-icon {
    font-size: 16px;
    line-height: inherit;
}
.cell{
    padding:  20px 40px 20px 100px;
    border-top: 1px solid #f5f6f7;
    width: 100%;
    position:relative;
    .van-icon{
        font-size: 26px;
        color:#c4c4c4;
        position: absolute;
        top: 40px;
        right: 10px;
    }
    .title{
        position: absolute;
        top: 24px;
        left: 22px;
        width: 65px;
        height: 65px;
        text-align: center;
        line-height: 65px;
        border-radius: 50%;
        font-size: 28px;
        color: #fff;
    }
    .flex{
        display: flex;
        justify-content: space-between;
    }
    p{
        font-size: 28px;
    }
    .time{
        font-size: 20px;
        color: #ccc;
    }
    .totalWeight{
        color: #1a1a1a;
    }
    .DiscountWeight{
        color: #6a6a6a;
        margin: 6px 0;
    }
    .config{
        margin-bottom: 8px;
        font-size: 24px;
        text-align: right;
    }
}
.keepJiesuan{
  background-color: #f7f7f7;
}
</style>
